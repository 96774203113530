import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// @mui
import { Grid, Typography, Drawer, Box, Stack, Button, IconButton, Fab, Divider, Link,
    CircularProgress, Alert, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FaAngleDoubleRight, FaExclamationTriangle, FaQuestionCircle, FaChevronDown } from 'react-icons/fa';

// components
import axios from '../../../utils/axios';
// ----------------------------------------------------------------------

export default function SupportDrawer() {

    const [ drawerOpen, setDrawerOpen ] = useState(false);
    const [ articlesLoading, setArticlesLoading ] = useState(true);    
    const [ articlesError, setArticlesError ] = useState(false);
    const [ articles, setArticles ] = useState([]);
    const [ videos, setVideos ] = useState([]);
    const location = useLocation();

    const navigate = useNavigate();

    const resetDrawer = () => { 
        setArticlesLoading(true);
        setArticlesError(false);
        setArticles([]);        
    }

    const openDrawer = () => { 
        resetDrawer();
        setDrawerOpen(true);
        loadArticles();
    }

    const openTicketingSystem = () => { 
        navigate('/tickets');
        resetDrawer();
        setDrawerOpen(false);
    }

    const openVideo = (url) => { 
        window.open(url);
    }

    const loadArticles = () => { 
        axios.post(`/app/dashboard/support-articles`, { relating_to: window.location.pathname })
        .then((response) => {             
            setArticles( response?.data?.articles );
            setVideos( response?.data?.videos );
        })
        .catch(() => { 
            setArticlesError(true);
        })
        .finally(() => { 
            setArticlesLoading(false);
        });
    }

    // Refresh the content if the page changes
    useEffect(() => {
        resetDrawer(); 
        loadArticles();
      }, [location.pathname]);

    return ( 
    <>
        { !location.pathname.includes('/auth/') && ( 
            <Fab color="primary" size="small" sx={{ 
                position: 'absolute', 
                right: '50px', 
                top: '20px', 
                padding: '0px', 
            }} onClick={() => openDrawer() }>
                <FaQuestionCircle />
            </Fab>
        )}

        <Drawer anchor="right" open={drawerOpen} onClose={ () => setDrawerOpen(false) } variant="persistent" sx={{ '& .MuiDrawer-paper': { boxShadow: '0px 0px 5px #aaa' }}}>
            <Box sx={{ width: '425px', padding: '20px' }}>                    
                <Grid container>
                    <Grid item xs={11}>
                        <Typography variant="h4" component="h3" mt={0.5} mb={2}>Support Centre</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton color="error" variant="outlined" onClick={ () => setDrawerOpen(false) }><FaAngleDoubleRight /></IconButton>
                    </Grid>
                </Grid>
                <Stack spacing={2}>
                    <Box>
                        <Typography variant="h5" component="h5" mb={1}>Working Feedback Academy</Typography>
                        <Typography mb={2} variant="body2" component="p">Explore our tutorials to learn how to get the most out of Working Feedback.</Typography>
                        <Link target="_blank" href="https://academy.workingfeedback.co.uk/tutorials"><Button size="small" fullWidth variant="contained" color="primaryGreen">Visit Working Feedback Academy</Button></Link>         
                    </Box>      
                    <br />
                    <Divider />
                    <Box>
                        <Typography variant="h5" component="h5" mb={1}>Raise a Ticket</Typography>
                        <Typography mb={2} variant="body2" component="p">Can't find what you're looking for in our help articles? Raise a support ticket, and a member of our team will 
                        be in contact with you ASAP.</Typography>
                       <Button size="small" fullWidth variant="contained" color="primaryNavy" onClick={() => openTicketingSystem() }>Go to ticketing system</Button>
                    </Box>              
                </Stack>
            </Box>
        </Drawer> 
    </>
    );

}