// components
import { FaLeaf, FaStarHalfAlt, FaChartBar } from 'react-icons/fa';
// import Label from '../../../components/Label';

// ----------------------------------------------------------------------

const iconStyle = { width: 18, height: 18 };

const groupNavConfig = (user) => { 

  const nav = [];

  // Top level menu
  const topLevel = { key: 'top-level', items: [] };
  const subSections = { key: 'subsections', items: [] };

  // Top level menu
  topLevel.items.push( { key: 'summary', title: 'Home', path: '/home', icon: <FaLeaf style={iconStyle} /> } );

  // Reviews menu
  if ( user?.accessLevel !== 'DEVELOPER') { 
    subSections.items.push({
      key: 'reviews', 
      title: 'Reviews',
      path: '/reviews',
      icon: <FaStarHalfAlt style={iconStyle} />,
      children: [
        { key: 'published-reviews', title: 'Published reviews', path: '/reviews/published' },
        { key: 'rejected-reviews', title: 'Rejected reviews', path: '/reviews/rejected' },
        { key: 'restricted-reviews', title: 'Restricted reviews', path: '/reviews/restricted' },
      ],
    });
  }

  // Analyse menu
  if ( user?.accessLevel !== 'DEVELOPER') { 
    const analyseMenu = { key: 'analyse', title: 'Analyse', path: '/analyse/analytics', icon: <FaChartBar style={iconStyle} />, children: [] };
    if ( user?.uses_dentally || user?.uses_exact ) { 
      analyseMenu.children.push( { key: 'review-data', title: 'Basic review data', path: '/analyse/analytics' } );  
      analyseMenu.children.push( { key: 'advanced-analytics', title: 'KPIs & mgmt information', path: '/analyse/advanced-analytics' } );
    } else { 
      analyseMenu.children.push( { key: 'review-data', title: 'Review data', path: '/analyse/analytics' } );
    }
    analyseMenu.children.push( { key: 'reports', title: 'Reports & spreadsheets', path: '/analyse/reports' } );
    analyseMenu.children.push( { key: 'sales-opportunities', title: 'Sales opportunities', path: '/analyse/opportunities' } );

    subSections.items.push(analyseMenu);    
  }


  nav.push( topLevel );
  nav.push( subSections );
  return nav;
}

export default groupNavConfig;
