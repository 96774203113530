// @mui
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemText, MenuItem, Menu, Typography } from '@mui/material';

// react
import { useState, useContext } from 'react'; 

import { DashboardModeContext } from '../../../contexts/DashboardModeContext';

// hooks
import useAuth from '../../../hooks/useAuth';
import useLocalStorage from '../../../hooks/useLocalStorage';

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1.25),
    marginTop: '5px !important', 
    width: '100%',
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  }));

// ----------------------------------------------------------------------

export default function NavbarCompanySelect() {

    const { user } = useAuth();
    const [ currentCompany, setCurrentCompany ] = useLocalStorage('company', null);
    const [ currentGroup, setCurrentGroup ] = useLocalStorage('group', null);
    
    const groupContext = useContext(DashboardModeContext);

    const companyOptions = user?.associations || [];
    const groupOptions = user?.groups || [];

    const [anchorEl, setAnchorEl] = useState(null);
    // const [selectedIndex, setSelectedIndex] = useState(1);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        if ( companyOptions[index].id !== currentCompany ) { 
            setCurrentCompany(companyOptions[index].id);
            setCurrentGroup(null);
            groupContext.exitGroupMode();            
        }
        setAnchorEl(null);
    };

    const handleGroupMenuItemClick = (event, index) => {
        if ( groupOptions[index].id !== currentGroup ) { 
            setCurrentGroup(groupOptions[index].id);
            setCurrentCompany(null);
            groupContext.enterGroupMode();
        }
        setAnchorEl(null);
    };

    const handleAdminMenuClick = () => {
        window.location.href = '/admin-select';
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <RootStyle>        
            <List component="nav" sx={{ bgcolor: 'transparent', color: '#fff', overflow: 'hidden' }}>
                <ListItem
                    id="company-selector-button"
                    aria-haspopup="listbox"
                    aria-controls="company-selector-menu"
                    aria-expanded={open ? 'true' : undefined} 
                    sx={{ paddingLeft: '5px', paddingRight: '15px', paddingBottom: '5px', paddingTop: '5px', cursor: 'pointer' }}
                    onClick={handleClickListItem}>
                    { groupContext.isGroupMode === false && ( 
                        <ListItemText 
                        primary={ <Typography variant="subtitle2" noWrap>{user?.company?.name}</Typography> }
                        secondary={ <Typography variant="body2" noWrap style={{ color: '#999999' }}>Reference: {user?.company?.id}</Typography> }
                    />
                    )}
                    { groupContext.isGroupMode === true && ( 
                        <ListItemText 
                        primary={ <Typography variant="subtitle2" noWrap>You're in group mode</Typography> }                        
                        secondary={ <Typography variant="body2" noWrap style={{ color: '#999999' }}>Tap here to return</Typography> }
                    />
                    )}                    
                </ListItem>
            </List>
            <Menu
                id="company-selector-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'company-selector-button', role: 'listbox' }}>
                    <MenuItem key="descriptor" sx={{ fontSize: '0.875rem' }} disabled>
                        Select a company to work on
                    </MenuItem>
                    {companyOptions.map((option, index) => (
                        <MenuItem
                            key={option.id}
                            sx={{ fontSize: '0.875rem' }}
                            selected={option.id === currentCompany && !groupContext.isGroupMode}
                            onClick={(event) => handleMenuItemClick(event, index)} >
                            <strong>{option.id}</strong>&nbsp;- {option.name}, {option.postcode}
                        </MenuItem>
                    ))}                    
                    {groupOptions.length > 0 && ( 
                        <MenuItem key="groupDescriptor" sx={{ fontSize: '0.875rem' }} disabled>
                        Select a group to work on
                    </MenuItem>
                    )}
                    { /* eslint-disable-next-line */ }
                    {groupOptions.map((option, index) => {  
                        if ( option.internal === false ) { 
                            return ( <MenuItem
                                key={`G${option.id}`}
                                sx={{ fontSize: '0.875rem' }}
                                selected={option.id === currentGroup && groupContext.isGroupMode}
                                onClick={(event) => handleGroupMenuItemClick(event, index)} >
                                <strong>Group:</strong>&nbsp;{option.name}
                            </MenuItem> )
                        }
                    })}
                    { user?.accessLevel === 'ADMIN' && ( 
                        <MenuItem key="admin" onClick={handleAdminMenuClick} sx={{ fontSize: '0.875rem', color: '#a6d143' }}>
                            <strong>Something else...</strong>
                        </MenuItem>
                    )}
            </Menu>
        </RootStyle>
    );
}