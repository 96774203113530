import PropTypes from 'prop-types';

// form
import { useFormContext, Controller } from 'react-hook-form';

// @mui
import { TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string, 
};

export default function RHFDatePicker({ name, label }) {
  const { control } = useFormContext();

  return (
    <>
        <Controller name={name} control={control} render={({ field }) => 
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label={label}
                    value={field.value}
                    allowSameDateSelection 
                    inputFormat='DD/MM/YYYY' 
                    {...field} 
                />
            </LocalizationProvider>
        }             
        />
    </>
  );
}
