import { useState, useEffect } from 'react';

// @mui
import { Container, Grid, Stack, Box, Typography, Card, CardContent, FormControlLabel, Checkbox, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Page from '../components/Page';
import DynamicGDPRAgreementText from '../components/workingfeedback/Other/DynamicGDPRAgreementText';

import useAuth from '../hooks/useAuth';
import axios from '../utils/axios';
// ----------------------------------------------------------------------

export default function GDPRAcceptScreen() {

  const { user } = useAuth();
  const [ textLoading, setTextLoading ] = useState(true);
  const [ textError, setTextError ] = useState(false);
  const [ agreement, setAgreement ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ checked, setChecked ] = useState(false);

  useEffect( () => { 
    setTextError(false);
    setTextLoading(true);
    setAgreement({});
    axios.get(`/app/dashboard/legal-agreement`, {})
        .then((response) => {             
            setAgreement(response.data);
            setTextLoading(false);
            setTextError(false);
        })
        .catch(() => { 
            setTextLoading(false);
            setTextError(true);
        });
  }, []);

  const handleCheckbox = (e) => { 
    setChecked( e.target.checked );
  }

  const acceptAgreement = () => {
    setError(false);
    setLoading(true);
    if ( !checked ) { 
        setError(true);
        setLoading(false);
    }

    axios.post(`/company/${window.localStorage.getItem('company')}/gdpr`, {})
        .then(() => {             
            setError(false);
            window.location.reload();
        })
        .catch(() => { 
            setLoading(false);
            setError(true);
        });
  }

  return (
    <Page title="Accept to continue">
    <Container maxWidth="xl">

        <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Typography variant="h6" component="h1" mb={6}>Please accept this agreement to continue</Typography>
                <Typography variant="h5" component="h1" mb={4}>To use your account, we just need you to accept this GDPR agreement.</Typography>

                <Typography variant="body2" component="div" mb={4}>In line with GDPR legislation we require you to accept the agreement opposite. The agreement is in 
                    relation to how we handle the data that you provide us.</Typography>

                <Box mb={10}>
                    <Card>
                        <CardContent>
                            <Stack spacing={2} direction="column">
                                <FormControlLabel control={<Checkbox disabled={textLoading} checked={checked} onChange={handleCheckbox}/>} label={`I, ${user?.name?.full}, accept this agreement on behalf of ${user?.company?.name}.`} />
                                { error && ( <Typography variant="body2" color="error">You must tick the box to accept the agreement.</Typography> ) }
                                <LoadingButton color="primaryNavy" onClick={acceptAgreement} variant="contained" loading={loading} disabled={textLoading}>Agree and continue</LoadingButton>
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>

                <Typography variant="body2">You can view a signed copy of this agreement at any time from your dashboard.</Typography>

            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                <Card>
                    <CardContent>
                        { textLoading && ( 
                            <>
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" /><br /><br />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" /><br /><br />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" /><br /><br />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                                <Skeleton variant="text" animation="wave" />
                            </>
                        )}

                        { !textLoading && !textError && ( <DynamicGDPRAgreementText company={user?.company} legalText={agreement?.content} /> )}                        
                        { !textLoading && textError && ( <p>Error - could not display the legal document. Please contact us to continue.</p> )}
                    </CardContent>
                </Card>

            </Grid>
        </Grid>    

    </Container>
    </Page>
  );
}
