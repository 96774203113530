// components
import { FaLeaf, FaStarHalfAlt, FaShareAltSquare, FaChartBar, FaCogs, FaPaperPlane, FaMailBulk, FaRegComments } from 'react-icons/fa';
// import Label from '../../../components/Label';

// ----------------------------------------------------------------------

const iconStyle = { width: 18, height: 18 };

const navConfig = (user) => { 

  const nav = [];

  // Top level menu
  const topLevel = { key: 'top-level', items: [] };
  const subSections = { key: 'subsections', items: [] };

  // Top level menu
  topLevel.items.push( { key: 'summary', title: 'Home', path: '/home', icon: <FaLeaf style={iconStyle} /> } );
  
  if  ( user?.company?.country.id === 1 || user?.company?.country.id === 2 || user?.company?.country.id === 3 ) {
    if ( user?.accessLevel !== 'ANALYST' ) { topLevel.items.push( { key: 'send-one-off-request', title: 'Send a one off request', path: '/collect/one-off', icon: <FaPaperPlane style={iconStyle} /> } ); }
    if ( user?.accessLevel !== 'ANALYST' && user?.accessLevel !== 'DEVELOPER' ) { topLevel.items.push( { key: 'request-bulk', title: 'Request in bulk', path: '/collect/bulk-requests', icon: <FaMailBulk style={iconStyle} /> } ); }
  }

  if  ( user?.company?.country.id === 4 ) {
    topLevel.items.push( { key: 'how-do--i', title: 'How do I collect feedback?', path: '/collect/how-do-i', icon: <FaPaperPlane style={iconStyle} /> } );
  }

  if ( user?.accessLevel !== 'ANALYST' && user?.accessLevel !== 'DEVELOPER' ) { topLevel.items.push( { key: 'upload-testimonial', title: 'Upload a testimonial', path: '/collect/testimonial', icon: <FaRegComments style={iconStyle} /> } ); }

  // Reviews menu
  if ( user?.accessLevel !== 'DEVELOPER') { 
    const reviewsMenu = { key: 'reviews', title: 'Reviews', path: '/reviews', icon: <FaStarHalfAlt style={iconStyle} />, children: [] };
    if ( user?.actionCentre === true ) { 
      reviewsMenu.children.push({ key: 'action-centre', title: 'Action centre', path: '/action-centre' });
    }
    reviewsMenu.children.push({ key: 'published-reviews', title: 'Published reviews', path: '/reviews/published' });
    reviewsMenu.children.push({ key: 'rejected-reviews', title: 'Rejected reviews', path: '/reviews/rejected' });
    reviewsMenu.children.push({ key: 'restricted-reviews', title: 'Restricted reviews', path: '/reviews/restricted' });
    reviewsMenu.children.push({ key: 'qrcode-collect', title: 'QR Codes', path: '/reviews/qrcode' });
    // reviewsMenu.children.push({ key: 'daily-summary', title: 'Daily summary', path: '/reviews/daily-summary' });  
    subSections.items.push(reviewsMenu);
  }

  // Share menu
  if ( user?.accessLevel !== 'RECEPTIONIST' && user?.accessLevel !== 'ANALYST' ) { 
    subSections.items.push({
      key: 'share', 
      title: 'Share',
      path: '/share',
      icon: <FaShareAltSquare style={iconStyle} />,
      children: [
        { key: 'web-features', title: 'Web features', path: '/share/web-features' },
        { key: 'social-media', title: 'Social media', path: '/share/social-media' },
        { key: 'integrations', title: 'Integrations', path: '/share/integrations' },
        // { key: 'promotional-material', title: 'Promotional material', path: '/share/promotional-material' },
      ],
    });
  }

  // Analyse menu
  if ( user?.accessLevel !== 'DEVELOPER') { 
    const analyseMenu = { key: 'analyse', title: 'Analyse', path: '/analyse/analytics', icon: <FaChartBar style={iconStyle} />, children: [] };
    if ( user?.company?.has_metrics && (user?.uses_dentally || user?.uses_exact) ) { 
      analyseMenu.children.push( { key: 'review-data', title: 'Basic review data', path: '/analyse/analytics' } );  
      analyseMenu.children.push( { key: 'advanced-analytics', title: 'KPIs & mgmt information', path: '/analyse/advanced-analytics' } );
    } else { 
      analyseMenu.children.push( { key: 'review-data', title: 'Review data', path: '/analyse/analytics' } );
    }
    if ( user?.company?.fft_eligible === true && user?.company?.country?.iso_code === 'GB' ) { 
      analyseMenu.children.push( { key: 'fft', title: 'Friends & family test', path: '/analyse/fft' } );
    }
    analyseMenu.children.push( { key: 'reports', title: 'Reports & spreadsheets', path: '/analyse/reports' } );
    analyseMenu.children.push( { key: 'certificates', title: 'Certificates', path: '/analyse/certificates' } );
    // analyseMenu.children.push( { key: 'referrals', title: 'Referrals', path: '/analyse/referral-data' } );
    analyseMenu.children.push( { key: 'sales-opportunities', title: 'Sales opportunities', path: '/analyse/opportunities' } );

    subSections.items.push(analyseMenu);    
  }

  // Customise menu
  const customiseMenu = { key: 'customise', title: 'Customise', path: '/customise/questions', icon: <FaCogs style={iconStyle} />, children: [] };

  if ( user?.accessLevel !== 'RECEPTIONIST' && user?.accessLevel !== 'ANALYST' && user?.accessLevel !== 'DEVELOPER' ) { 
    customiseMenu.children.push( { key: 'your-questions', title: 'Your questions', path: '/customise/questions' } );
    customiseMenu.children.push( { key: 'products-services', title: 'Your products & services', path: '/customise/products-and-services' } );
  }

  if ( user?.accessLevel !== 'RECEPTIONIST' && user?.accessLevel !== 'ANALYST' ) { 
    customiseMenu.children.push( { key: 'social-media-graphics', title: 'Facebook share graphic', path: '/customise/social-media' } );
    customiseMenu.children.push( { key: 'email-templates', title: 'Email templates', path: '/customise/email-templates' } );
  }

  if ( customiseMenu.children.length > 0 ) { 
    subSections.items.push( customiseMenu );
  }  

  nav.push( topLevel );
  nav.push( subSections );
  return nav;
}

export default navConfig;
