
// API
// ----------------------------------------------------------------------
export const DASHBOARD_VERSION = '2.1.0';
export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';


// FACEBOOK INTEGRATION
export const FACEBOOK_API_APPID = '1448895852022947';
export const FACEBOOK_API_REDIRECT_URI = 'https://myworkingfeedback.com/share/social-media/facebook/new';

// TWITTER INTEGRATION
export const TWITTER_CLIENT_ID = 'al9IMlhNU3doQXlXQ3VQajZ1MGo6MTpjaQ';
export const TWITTER_REDIRECT_URI = 'https://myworkingfeedback.com/share/social-media/twitter/new';
export const TWITTER_SCOPES = 'tweet.read tweet.write tweet.moderate.write offline.access users.read';

// DENTALLY INTEGRATION
export const DENTALLY_API_APPID = 'bfe5e1bcc6a19da4c745d69e8bc4ad747e669e94c152a2d4f05249470cd823af';
export const DENTALLY_API_REDIRECT_URI = 'https://myworkingfeedback.com/share/integrations/dentally/new';
export const DENTALLY_WEBHOOK_PAYLOAD = 'https://api.workingfeedback.co.uk/webhooks/dentally-v2';

// GOOGLE INTEGRATION
export const GOOGLE_API_APPID = '544570974465-mm2r0r61smjc0qh1ivvfecg12tgeh229.apps.googleusercontent.com';
export const GOOGLE_API_REDIRECT_URI = 'https://myworkingfeedback.com/share/integrations/google/new';
export const GOOGLE_API_SCOPES = 'https://www.googleapis.com/auth/business.manage';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = '/';

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 68,
  DASHBOARD_DESKTOP_HEIGHT: 72,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 72 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: true,
};
