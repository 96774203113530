import React from 'react';
import PropTypes from 'prop-types';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import useAuth from '../hooks/useAuth';
import { DASHBOARD_VERSION } from '../config';

function BugsnagContainer({children})
{
    const { user } = useAuth();
    window.bugsnagUser = user;

    Bugsnag.start({  
        apiKey: '9f8e4a3e646f2b96df52223b539d8b49',
        appVersion: DASHBOARD_VERSION,
        plugins: [new BugsnagPluginReact()],       
        onError: (event) => {
            event.setUser( window.bugsnagUser?.id || 0, window.bugsnagUser?.email || 'notloggedin@workingfeedback.co.uk', window.bugsnagUser?.name?.full || 'Not logged in' );
            event.addMetadata('company', {
                id: window.bugsnagUser?.company?.id, 
                name: window.bugsnagUser?.company?.name,
                category_id: window.bugsnagUser?.company?.category?.id, 
                category_name: window.bugsnagUser?.company?.category?.name
            });
        }
    });    
      
    const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

    return (
        <ErrorBoundary>
            {children}
        </ErrorBoundary>
    );
}

BugsnagContainer.propTypes = {
    children: PropTypes.node,
};

export default BugsnagContainer;