import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards

import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import GDPRAgreementGuard from '../guards/GDPRAgreementGuard';
import OnboardingGuard from '../guards/OnboardingGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';

// components
import LoadingScreen from '../components/LoadingScreen';
import MaintenancePage from '../pages/MaintenancePage';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
);

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'reset-password-verify', element: <VerifyPasswordReset /> },
        { path: 'reset-password', element: <ResetPassword /> },        
        { path: 'soft-launch-migrate', element: <SoftLaunchMigrateLogin /> },        
        { path: 'maintenance', element: <MaintenancePage /> },
      ],
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>            
            <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/home" replace />, index: true },
        { path: 'home', element: <GDPRAgreementGuard><OnboardingGuard><DashboardHome /></OnboardingGuard></GDPRAgreementGuard> },
        { path: 'feedback-requests', element: <GDPRAgreementGuard><OnboardingGuard><FeedbackRequests /></OnboardingGuard></GDPRAgreementGuard> },
        { path: 'admin-select', element: <AdminCompanySelect /> },
        { path: 'user/account', element: <GDPRAgreementGuard><OnboardingGuard><MyAccount /></OnboardingGuard></GDPRAgreementGuard> }, 
        { path: 'user/notifications', element: <GDPRAgreementGuard><OnboardingGuard><NotificationPreferences /></OnboardingGuard></GDPRAgreementGuard> }, 
        { path: 'company/settings', element: <GDPRAgreementGuard><OnboardingGuard><CompanySettings /></OnboardingGuard></GDPRAgreementGuard> }, 
        { path: 'gdpr-agreement-copy', element: <GDPRAgreementGuard><OnboardingGuard><GDPRAgreementCopy /></OnboardingGuard></GDPRAgreementGuard> }, 
        { path: 'tickets/:ticketID', element: <OnboardingGuard><TicketDetail /></OnboardingGuard> }, 
        { path: 'tickets', element: <OnboardingGuard><Tickets /></OnboardingGuard> }, 
        { path: 'support', element: <OnboardingGuard><HelpAndSupport /></OnboardingGuard> }, 
        { 
          path: 'action-centre', 
          children: [
            { element: <GDPRAgreementGuard><OnboardingGuard><ActionCentre /></OnboardingGuard></GDPRAgreementGuard>, index: true },
            { path: 'review/:reviewID', element: <GDPRAgreementGuard><OnboardingGuard><ActionFIReview /></OnboardingGuard></GDPRAgreementGuard> },            
          ]
        }, 
        {
          path: 'collect',
          children: [
            { element: <Navigate to="/collect/one-off" />, index: true },
            { path: 'how-do-i', element: <GDPRAgreementGuard><OnboardingGuard><IntegratedCollectHelp /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'one-off', element: <GDPRAgreementGuard><OnboardingGuard><OneOffRequest /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'bulk-requests', element: <GDPRAgreementGuard><OnboardingGuard><BulkRequests /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'bulk-requests/:batchID', element: <GDPRAgreementGuard><OnboardingGuard><BulkRequestResults /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'testimonial', element: <GDPRAgreementGuard><OnboardingGuard><TestimonialUpload /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'sms-customisation', element: <GDPRAgreementGuard><OnboardingGuard><SMSCustomisation /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'dns-authentication', element: <GDPRAgreementGuard><OnboardingGuard><DNSAuthentication /></OnboardingGuard></GDPRAgreementGuard> },
          ],
        },
        {
          path: 'reviews',
          children: [
            { element: <Navigate to="/reviews/published" />, index: true },
            { path: 'published', element: <GDPRAgreementGuard><OnboardingGuard><PublishedReviews /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'rejected', element: <GDPRAgreementGuard><OnboardingGuard><RejectedReviews /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'restricted', element: <GDPRAgreementGuard><OnboardingGuard><RestrictedReviews /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'qrcode', element: <GDPRAgreementGuard><OnboardingGuard><QRCodeCollect /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'daily-summary', element: <GDPRAgreementGuard><OnboardingGuard><DailySummary /></OnboardingGuard></GDPRAgreementGuard> },
          ],
        },
        {
          path: 'share',
          children: [
            { element: <Navigate to="/share/web-features" />, index: true },
            { path: 'web-features', element: <GDPRAgreementGuard><OnboardingGuard><WebFeaturesIndex /></OnboardingGuard></GDPRAgreementGuard> },
            { 
              path: 'social-media', 
              children: [ 
                { element: <GDPRAgreementGuard><OnboardingGuard><SocialMediaIndex /></OnboardingGuard></GDPRAgreementGuard>, index: true },
                // { path: 'facebook', element: <GDPRAgreementGuard><OnboardingGuard><Facebook /></OnboardingGuard></GDPRAgreementGuard> },
                // { path: 'facebook/new', element: <GDPRAgreementGuard><OnboardingGuard><FacebookLink /></OnboardingGuard></GDPRAgreementGuard> },
                { path: 'twitter', element: <GDPRAgreementGuard><OnboardingGuard><Twitter /></OnboardingGuard></GDPRAgreementGuard> },
                { path: 'twitter/new', element: <GDPRAgreementGuard><OnboardingGuard><TwitterLink /></OnboardingGuard></GDPRAgreementGuard> },
                { path: 'instagram', element: <GDPRAgreementGuard><OnboardingGuard><Instagram /></OnboardingGuard></GDPRAgreementGuard> },
              ], 
            },
            {
              path: 'integrations', 
              children: [ 
                { element: <GDPRAgreementGuard><OnboardingGuard><IntegrationsIndex /></OnboardingGuard></GDPRAgreementGuard>, index: true },
                { path: 'google', element: <GDPRAgreementGuard><OnboardingGuard><Google /></OnboardingGuard></GDPRAgreementGuard> },
                { path: 'google/new', element: <GDPRAgreementGuard><OnboardingGuard><GoogleLink /></OnboardingGuard></GDPRAgreementGuard> },
                { path: 'soe', element: <GDPRAgreementGuard><OnboardingGuard><SOEExact /></OnboardingGuard></GDPRAgreementGuard> },
                { path: 'dentally', element: <GDPRAgreementGuard><OnboardingGuard><Dentally /></OnboardingGuard></GDPRAgreementGuard> },
                { path: 'dentally/new', element: <GDPRAgreementGuard><DentallyLink /></GDPRAgreementGuard> },
                // { path: 'sfd', element: <GDPRAgreementGuard><OnboardingGuard><SFD /></OnboardingGuard></GDPRAgreementGuard> },
                // { path: 'r4', element: <GDPRAgreementGuard><OnboardingGuard><R4 /></OnboardingGuard></GDPRAgreementGuard> },
                // { path: 'aerona', element: <GDPRAgreementGuard><OnboardingGuard><Aerona /></OnboardingGuard></GDPRAgreementGuard> },
              ], 
            }, 
            { path: 'promotional-material', element: <GDPRAgreementGuard><OnboardingGuard><PromotionalMaterial /></OnboardingGuard></GDPRAgreementGuard> },            
          ],
        },
        { 
          path: 'web-feature',               
          children: [ 
            { element: <Navigate to="/share/web-features" />, index: true },
            { path: 'review-tab', element: <GDPRAgreementGuard><OnboardingGuard><ReviewTab /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'carousel', element: <GDPRAgreementGuard><OnboardingGuard><Carousel /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'review-feed', element: <GDPRAgreementGuard><OnboardingGuard><ReviewFeed /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'online-form', element: <GDPRAgreementGuard><OnboardingGuard><OnlineForm /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'floating-review-score', element: <GDPRAgreementGuard><OnboardingGuard><FloatingReviewScore /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'review-widget', element: <GDPRAgreementGuard><OnboardingGuard><ReviewWidget /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'trust-badge', element: <GDPRAgreementGuard><OnboardingGuard><TrustBadge /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'google-review-button', element: <GDPRAgreementGuard><OnboardingGuard><GoogleReviewButton /></OnboardingGuard></GDPRAgreementGuard> },
          ]
        }, 
        {
          path: 'analyse',
          children: [
            { element: <Navigate to="/analyse/analytics" />, index: true },
            { path: 'analytics', element: <GDPRAgreementGuard><OnboardingGuard><Analytics /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'advanced-analytics', element: <GDPRAgreementGuard><OnboardingGuard><AdvancedAnalytics /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'fft', element: <GDPRAgreementGuard><OnboardingGuard><FriendsFamilyTest /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'reports', element: <GDPRAgreementGuard><OnboardingGuard><Reports /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'certificates', element: <GDPRAgreementGuard><OnboardingGuard><Certificates /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'referral-data', element: <GDPRAgreementGuard><OnboardingGuard><Referrals /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'opportunities/:opportunityID', element: <GDPRAgreementGuard><OnboardingGuard><SalesOpportunity /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'opportunities', element: <GDPRAgreementGuard><OnboardingGuard><SalesOpportunities /></OnboardingGuard></GDPRAgreementGuard> },            
            { path: 'compare', element: <GDPRAgreementGuard><OnboardingGuard><IndustryComparison /></OnboardingGuard></GDPRAgreementGuard> },
          ],
        },
        {
          path: 'customise',
          children: [
            { element: <Navigate to="/customise/questions" />, index: true },
            { path: 'questions', element: <GDPRAgreementGuard><OnboardingGuard><QuestionSet /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'questions/editor', element: <GDPRAgreementGuard><OnboardingGuard><QuestionSetEditor /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'questions/editor/:questionID', element: <GDPRAgreementGuard><OnboardingGuard><QuestionSetEditIndividual /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'social-media', element: <GDPRAgreementGuard><OnboardingGuard><SocialMediaGraphic /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'email-templates', element: <GDPRAgreementGuard><OnboardingGuard><EmailTemplate /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'products-and-services', element: <GDPRAgreementGuard><OnboardingGuard><ProductsAndServices /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'info-page/:mappingID', element: <GDPRAgreementGuard><OnboardingGuard><InfoPageEditor /></OnboardingGuard></GDPRAgreementGuard> },
            { path: 'info-page', element: <GDPRAgreementGuard><OnboardingGuard><ServiceInfoPage /></OnboardingGuard></GDPRAgreementGuard> },
          ],
        },
        {
          path: 'onboarding',
          children: [
            { element: <Navigate to="/" />, index: true },
            { path: 'dentally', element: <GDPRAgreementGuard><OBLinkDentallyPage /></GDPRAgreementGuard> },
            { path: 'soe', element: <GDPRAgreementGuard><OBLinkSOEPage /></GDPRAgreementGuard> },
            { path: 'users', element: <GDPRAgreementGuard><OBAddUsersPage /></GDPRAgreementGuard> },
            { path: 'complete', element: <GDPRAgreementGuard><OBCompletePage /></GDPRAgreementGuard> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION AND ACCOUNTS
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyPasswordReset = Loadable(lazy(() => import('../pages/auth/VerifyPasswordReset')));
const SoftLaunchMigrateLogin = Loadable(lazy(() => import('../pages/auth/SoftLaunchMigrateLogin')));
const AdminCompanySelect = Loadable(lazy(() => import('../pages/AdminCompanySelect')));
const MyAccount = Loadable(lazy(() => import('../pages/settings/MyAccount')));
const CompanySettings = Loadable(lazy(() => import('../pages/settings/CompanySettings')));
const GDPRAgreementCopy = Loadable(lazy(() => import('../pages/settings/GDPRAgreementCopy')));
const NotificationPreferences = Loadable(lazy(() => import('../pages/settings/NotificationPreferences')));

// ONBOARDING
const OBLinkDentallyPage = Loadable( lazy(() => import('../pages/onboarding/LinkDentallyPage')) );
const OBLinkSOEPage = Loadable( lazy(() => import('../pages/onboarding/LinkSOEPage')) );
const OBAddUsersPage = Loadable( lazy(() => import('../pages/onboarding/AddUsersPage')) );
const OBCompletePage = Loadable( lazy(() => import('../pages/onboarding/OnboardingCompletePage')) );

// COLLECT
const IntegratedCollectHelp = Loadable( lazy(() => import('../pages/collect/IntegratedCollectHelp')) );
const OneOffRequest = Loadable( lazy(() => import('../pages/collect/OneOffRequest')) );
const BulkRequests = Loadable( lazy(() => import('../pages/collect/BulkRequests')) );
const BulkRequestResults = Loadable( lazy(() => import('../pages/collect/BulkRequestResults')) );
const TestimonialUpload = Loadable( lazy(() => import('../pages/collect/TestimonialUpload')) );
const SMSCustomisation = Loadable( lazy(() => import('../pages/collect/SMSCustomisation')) );
const DNSAuthentication = Loadable( lazy(() => import('../pages/collect/DNSAuthentication')) );

// REVIEWS
const PublishedReviews = Loadable( lazy(() => import('../pages/reviews/PublishedReviews')) );
const RejectedReviews = Loadable( lazy(() => import('../pages/reviews/RejectedReviews')) );
const RestrictedReviews = Loadable( lazy(() => import('../pages/reviews/RestrictedReviews')) );
const QRCodeCollect = Loadable( lazy(() => import('../pages/collect/QRCodeCollect')) );
const DailySummary = Loadable( lazy(() => import('../pages/reviews/DailySummary')) );

// SHARE
const WebFeaturesIndex = Loadable( lazy(() => import('../pages/share/WebFeaturesIndex')) );
const SocialMediaIndex = Loadable( lazy(() => import('../pages/share/SocialMediaIndex')) );
const IntegrationsIndex = Loadable( lazy(() => import('../pages/share/IntegrationsIndex')) );
const PromotionalMaterial = Loadable( lazy(() => import('../pages/share/PromotionalMaterial')) );

// SOCIAL MEDIA
// const Facebook = Loadable( lazy(() => import('../pages/share/social/Facebook')) );
// const FacebookLink = Loadable( lazy(() => import('../pages/share/social/FacebookLink')) );
const Twitter = Loadable( lazy(() => import('../pages/share/social/Twitter')) );
const TwitterLink = Loadable( lazy(() => import('../pages/share/social/TwitterLink')) );
const Instagram = Loadable( lazy(() => import('../pages/share/social/Instagram')) );

// INTEGRATIONS
const Google = Loadable( lazy(() => import('../pages/share/integrations/Google')) );
const GoogleLink = Loadable( lazy(() => import('../pages/share/integrations/GoogleLink')) );
const Dentally = Loadable( lazy(() => import('../pages/share/integrations/Dentally')) );
const DentallyLink = Loadable( lazy(() => import('../pages/share/integrations/DentallyLink')) );
const SOEExact = Loadable( lazy(() => import('../pages/share/integrations/SOEExact')) );
// const SFD = Loadable( lazy(() => import('../pages/share/integrations/SFD')) );
// const R4 = Loadable( lazy(() => import('../pages/share/integrations/R4')) );
// const Aerona = Loadable( lazy(() => import('../pages/share/integrations/Aerona')) );

// WEB FEATURES
const ReviewTab = Loadable( lazy(() => import('../pages/share/webfeatures/ReviewTab')) );
const Carousel = Loadable( lazy(() => import('../pages/share/webfeatures/Carousel')) );
const ReviewFeed = Loadable( lazy(() => import('../pages/share/webfeatures/ReviewFeed')) );
const OnlineForm = Loadable( lazy(() => import('../pages/share/webfeatures/OnlineForm')) );
const FloatingReviewScore = Loadable( lazy(() => import('../pages/share/webfeatures/FloatingReviewScore')) );
const ReviewWidget = Loadable( lazy(() => import('../pages/share/webfeatures/ReviewWidget')) );
const TrustBadge = Loadable( lazy(() => import('../pages/share/webfeatures/TrustBadge')) );
const GoogleReviewButton = Loadable( lazy(() => import('../pages/share/webfeatures/GoogleReviewButton')) );

// ANALYSE
const Analytics = Loadable( lazy(() => import('../pages/analyse/Analytics')) );
const AdvancedAnalytics = Loadable( lazy(() => import('../pages/analyse/AdvancedAnalytics')) );
const Reports = Loadable( lazy(() => import('../pages/analyse/Reports')) );
const FriendsFamilyTest = Loadable( lazy(() => import('../pages/analyse/FriendsFamilyTest')) );
const Certificates = Loadable( lazy(() => import('../pages/analyse/Certificates')) );
const Referrals = Loadable( lazy(() => import('../pages/analyse/Referrals')) );
const SalesOpportunities = Loadable( lazy(() => import('../pages/analyse/SalesOpportunities')) );
const SalesOpportunity = Loadable( lazy(() => import('../pages/analyse/SalesOpportunity')) );
const IndustryComparison = Loadable( lazy(() => import('../pages/analyse/IndustryComparison')) );

// CUSTOMISE
const QuestionSet = Loadable( lazy(() => import('../pages/customise/QuestionSet')) );
const QuestionSetEditor = Loadable( lazy(() => import('../pages/customise/QuestionSetEditor')) );
const QuestionSetEditIndividual = Loadable( lazy(() => import('../pages/customise/QuestionSetEditIndividual')) );
const SocialMediaGraphic = Loadable( lazy(() => import('../pages/customise/SocialMediaGraphic')) );
const EmailTemplate = Loadable( lazy(() => import('../pages/customise/EmailTemplate')) );
const ProductsAndServices = Loadable( lazy(() => import('../pages/customise/ProductsAndServices')) );
const ServiceInfoPage = Loadable( lazy(() => import('../pages/customise/ServiceInfoPage')) );
const InfoPageEditor = Loadable( lazy(() => import('../pages/customise/InfoPageEditor')) );

// MISCELLANEOUS
const DashboardHome = Loadable( lazy(() => import('../pages/DashboardHome')) );
const FeedbackRequests = Loadable( lazy(() => import('../pages/FeedbackRequests')) );
const ActionCentre = Loadable( lazy( () => import('../pages/ActionCentre')) );
const ActionFIReview = Loadable( lazy( () => import('../pages/ActionFIReview')) );
const Tickets = Loadable( lazy( () => import('../pages/Tickets')) );
const TicketDetail = Loadable( lazy( () => import('../pages/TicketDetail')) );
const HelpAndSupport = Loadable( lazy( () => import('../pages/HelpAndSupport')) );
const Page500 = Loadable( lazy(() => import('../pages/Page500')) );
const NotFound = Loadable( lazy(() => import('../pages/Page404')) );
