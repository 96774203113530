import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// @mui
import { Box } from '@mui/material';

import { ReactComponent as FullLogo } from './logo/full-normal.svg';
import { ReactComponent as FullInvertedLogo } from './logo/full-inverted.svg';

// ----------------------------------------------------------------------

Logo.propTypes = {
  inverted: PropTypes.bool, 
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ inverted = false, disabledLink = false, sx }) {

  const fullLogo = (
    <Box sx={{ width: 180, ...sx }}>
      <FullLogo />
    </Box>
  );

  const fullInvertedLogo = (
    <Box sx={{ width: 180, ...sx }}>
      <FullInvertedLogo />
    </Box>
  );

  if (disabledLink) {
    return inverted ? <>{fullInvertedLogo}</> : <>{fullLogo}</>;
  }

  return <RouterLink to="/">{ inverted ? fullInvertedLogo : fullLogo }</RouterLink>;
}
