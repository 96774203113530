import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import useAuth from '../../../hooks/useAuth';
// ----------------------------------------------------------------------


DynamicGDPRAgreementText.propTypes = {
    date: PropTypes.string,
    company: PropTypes.object, 
    acceptedBy: PropTypes.string, 
    legalText: PropTypes.string,
};

export default function DynamicGDPRAgreementText(props) {  

  const todayLongDate = () => { 
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const today  = new Date();
    return today.toLocaleDateString("en-GB", options);
  }

  const today = todayLongDate();
  const { user } = useAuth();

  let legalText = props?.legalText;
  legalText = legalText.replace('[COMPANY_NAME]', props?.company?.name);
  legalText = legalText.replace('[COMPANY_ADDRESS]', props?.company?.address?.formatted);
  
  return (
    <Typography variant="body2" component="div" dangerouslySetInnerHTML={{ __html: legalText }} /> 
  );
}


