import PropTypes from 'prop-types';
import { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';

// ----------------------------------------------------------------------

const DashboardModeContext = createContext({
    isGroupMode: false, 
    enterGroupMode: () => {}, 
    exitGroupMode: () => {}, 
  });

// ----------------------------------------------------------------------

DashboardModeContextProvider.propTypes = {
  children: PropTypes.node,
};

function DashboardModeContextProvider({ children }) {
  
  const [ groupMode, setGroupMode ] = useLocalStorage('groupMode', false);
  const navigate = useNavigate();

  const enterGroupMode = () => {    
    setGroupMode(true);
    window.localStorage.removeItem('company');
    navigate('/');
    window.location.reload();
  };

  const exitGroupMode = () => {    
    setGroupMode(false);
    window.localStorage.removeItem('group');
    navigate('/');
    window.location.reload();
  };

  return (
    <DashboardModeContext.Provider
      value={{
        isGroupMode: groupMode,
        enterGroupMode,
        exitGroupMode, 
      }}
    >
      {children}
    </DashboardModeContext.Provider>
  );
}

export { DashboardModeContextProvider, DashboardModeContext };
