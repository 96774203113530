import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// @mui
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
  backgroundImage: 'url("/assets/royal-blue-background.png")', 
  backgroundSize: 'cover', 
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  color: '#ffffff', 
}));

// ----------------------------------------------------------------------

export default function MaintenancePage() {
  return (
    <Page title="Down for maintenance" sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'left' }}>
            <Typography variant="h3" paragraph>
              Down for maintenance
            </Typography>
            <Typography variant="body2" mb={5}>We're conducting some essential maintenance work on the Working Feedback service right now, so your dashboard is 
            temporarily unavailable.</Typography>

            <Button to="/" color="primaryGreen" variant="contained" component={RouterLink} sx={{ textDecoration: 'none' }}>
              Go to Dashboard Home
            </Button>

            <Typography variant="body2" mt={8}>
                <small><strong>Got an urgent query?</strong> Contact us on 0800 043 2100.
                </small>
            </Typography>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
