// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, CardContent, Stack, Container, Typography, Grid, Alert } from '@mui/material';

// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import { DASHBOARD_VERSION } from '../../config';

// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  backgroundImage: 'url("/assets/royal-blue-background.png")', 
  backgroundSize: 'cover', 
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: { 'xs': 480, 'sm': 480, 'md': 480, 'lg': '800px' },
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {

  return (
    <Page title="Login">
      <RootStyle>

        <Container maxWidth="md">
          <ContentStyle>
          <Box sx={{ textAlign: 'right', width: '100%', p: 1 }}>
              <Typography variant="body2" style={{ color: '#FFFFFF', fontSize: '11px' }} component="div"><strong>Client Dashboard {DASHBOARD_VERSION}</strong>&nbsp;|&nbsp;
              { (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'Development Build' : 'Production' }
              </Typography>
            </Box>
            <Card>
              <CardContent>
                <Box mb={2}>
                  <Logo />
                  { DASHBOARD_VERSION.toLowerCase().includes('beta') && ( 
                    <Alert sx={{ marginTop: '10px' }} severity="warning">This is pre-release BETA software. Features and functionality may be subject to change.</Alert>
                  )}                  
                </Box>                            
                <Grid container spacing={5}>
                  <Grid item xs={12} md={6}>                    
                    <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>                
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h4" gutterBottom>
                          Sign in to Dashboard
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Enter your login details below</Typography>
                      </Box>
                    </Stack>              
                    <LoginForm />    
                  </Grid>
                  <Grid item md={6} sx={{ display: { 'xs': 'none', 'md': 'flex' }}}>
                    <img src="/graphics/stock9.png" sx={{ maxWidth: '100%', width: '100%' }} alt="Woman holding a laptop" />
                  </Grid>
                </Grid>                
              </CardContent>
            </Card>            
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
