import PropTypes from 'prop-types';

// hooks
import useAuth from '../hooks/useAuth';

// components
import GDPRAcceptScreen from '../pages/GDPRAcceptScreen';

// ----------------------------------------------------------------------

GDPRAgreementGuard.propTypes = {
  children: PropTypes.node,
};

export default function GDPRAgreementGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if ( isAuthenticated && !user?.accessLevel?.includes('ADMIN') && user?.company?.gdpr_accepted === false ) {
    return ( <GDPRAcceptScreen /> );
  }

  return <>{children}</>;
}
