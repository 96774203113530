import PropTypes from 'prop-types';
import { useEffect } from 'react';

// hooks
import { useNavigate } from 'react-router';
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

OnboardingGuard.propTypes = {
  children: PropTypes.node,
};

export default function OnboardingGuard({ children }) {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  useEffect( () => { 
    if ( isAuthenticated && user?.company?.onboarding_required === true && !user?.accessLevel?.includes('ADMIN') ) {

        if ( user?.company?.sms_billing === 'dentally' && user?.company?.has_linked_dentally === false ) { 
            navigate('/onboarding/dentally');
            return ( <></> );
        }
    
        if ( user?.company?.sms_billing === 'soe' && user?.company?.has_linked_soe === false ) { 
            navigate('/onboarding/soe');
            return ( <></> );
        }
    
        navigate('/onboarding/users');
        return ( <></> );
      }
    
      return ( <>{children}</> );
      // eslint-disable-next-line
  }, []);

  return ( <>{children}</> );
}
