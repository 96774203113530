import PropTypes from 'prop-types';
// @mui
import { List, Box } from '@mui/material';
//
import { NavListRoot } from './NavList';
// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, ...other }) {
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.key} disablePadding sx={{ px: 2 }}>
          {group.items.map((list) => (
            <NavListRoot key={list.title} list={list} />
          ))}
        </List>
      ))}
    </Box>
  );
}
