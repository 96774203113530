import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.defaults.headers.common.company = window.localStorage.getItem('company');
axiosInstance.defaults.headers.common.group = window.localStorage.getItem('group');
axiosInstance.defaults.headers.common.Accept = 'application/json';

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => { 
    if ( error?.response?.status === 403 && error?.response?.data?.maintenance === true ) {
      window.location.href = '/auth/maintenance';
      return Promise.reject((error.response && error.response?.data) || 'Something went wrong')
    }

    if ( error.code === 'ECONNABORTED' ) { 
      return Promise.reject(error.code || 'Something went wrong');
    }

    return Promise.reject((error.response && error.response?.data) || 'Something went wrong')
  }
);

export default axiosInstance;
